import { authRequest } from '@/auth/utils'


export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    POST_EFFECT(context, effectData) {
        const formData = new FormData()
        formData.append('editor', effectData.editor)
        formData.append('effect_type', effectData.effect_type)
		formData.append('hdr_loader_type', effectData.hdr_loader_type)
        formData.append('meshes_list_json', effectData.meshes_list_json_stringified)

        return authRequest
			.post('effect/', formData)
			.then(res => {
				if (res.status === 201) {
					if (effectData.has_custom_hdr) {
						for (let i = 0; i <= effectData.customHDRFiles.length; i++) {
							let hdrFile = effectData.customHDRFiles[i]
							if (hdrFile) {
								context.dispatch('POST_HDR', {
									effectID: res.data.id,
									hdrFile: hdrFile,
									number: i,
								})
							}
						}
					}
				}
				return res
			})
    },
    PATCH_EFFECT(context, effectData) {
        const formData = new FormData()
        formData.append('effect_type', effectData.effect_type)
		formData.append('hdr_loader_type', effectData.hdr_loader_type)
        formData.append('meshes_list_json', effectData.meshes_list_json_stringified)

        return authRequest
			.patch(`effect/${effectData.id}/`, formData)
			.then(res => {
				if (res.status === 200) {
					if (effectData.hadCustomHDR === true && effectData.has_custom_hdr === false) {
						context.dispatch('DELETE_HDRS_BY_EFFECT', effectData.id).then(res => {
							if (res.status == 200) {
								context.commit('Effects/ADD_HAD_HDR_STATUS_FOR_EFFECT', {effectID: effectData.id, status: false}, { root: true })
							}
						})
					} else if (effectData.has_custom_hdr) {
						for (let i = 0; i <= effectData.customHDRFiles.length; i++) {
							let hdrFile = effectData.customHDRFiles[i]
							if (hdrFile) {
								context.dispatch('POST_HDR', {
									effectID: effectData.id,
									hdrFile: hdrFile,
									number: i,
								}).then(res => {
									if (res.status == 201) {
										// Allows to delete the hdr without page refresh
										context.commit('Effects/ADD_HAD_HDR_STATUS_FOR_EFFECT', {effectID: effectData.id, status: true}, { root: true })
									}
								})
							}
						}
					} else {
					}
				}
				return res
			})
    },
    DELETE_EFFECT(context, effectID) {
		return authRequest.delete(`effect/${effectID}`)
    },
	POST_HDR(context, hdrData) {
		const formData = new FormData()
		formData.append('effect', hdrData.effectID)
		formData.append('hdr_file', hdrData.hdrFile)
		formData.append('number', hdrData.number)

		return authRequest.post('/hdr/', formData)
	},
	DELETE_HDRS_BY_EFFECT(context, effectID) {
		return authRequest.delete(`effect/${effectID}/remove_hdrs`)
	}
  }
}
